import {
    disableBodyScroll,
    enableBodyScroll,
    Popup,
    popupContentSlotClassNames,
    pxToRem,
    TopNavigationProps as AriaTopNavigationProps,
    Flex,
    Box,
    pxArrayToRem,
    Grid,
    Caption,
    Divider,
    useTheme,
    AppDisplayModeType,
} from '@archipro-design/aria';

import { useCountryContext } from '@archipro-website/localisation';
import type { SupportedCountry } from '@archipro-website/localisation';

import {
    FlagAU,
    FlagNZ,
    CheckLine,
    ChevronDown,
    svgIconClassName,
} from '@archipro-design/icons';
import { Actions } from 'ahooks/lib/useBoolean';
import React, { useEffect, useRef, useState } from 'react';

import { TopNavRef, TopnavClientEnvironment } from '../types';
import { Location } from 'history';
import { useUpdateEffect } from 'ahooks';

const pathsThatShouldRedirectToHome = [
    'product',
    'professional',
    'project',
    'article',
];

export function useFlagSlot(
    appDisplayMode: AppDisplayModeType,
    topNavRef: TopNavRef,
    menuActionsState: [boolean, Actions],
    flagMenuState: [boolean, Actions],
    env: TopnavClientEnvironment,
    location?: Location
): AriaTopNavigationProps['flagSlot'] {
    const avatarRef = useRef<HTMLDivElement>(null);
    const personalMenuRef = useRef<HTMLDivElement>(null);

    const theme = useTheme();

    const flagMenuRef = useRef<HTMLDivElement>(null);
    const countryCtx = useCountryContext();
    const [countryCode, setCountryCode] = useState<SupportedCountry>(
        countryCtx.code
    );

    const target = (
        appDisplayMode.desktop ? avatarRef.current : topNavRef.current
    ) as HTMLDivElement;

    const isFlagMenuOpen = menuActionsState[0] || flagMenuState[0];
    useEffect(() => {
        if (!personalMenuRef?.current || !appDisplayMode.mobile) return;
        const target = personalMenuRef.current;
        const mainList = target.childNodes[0];
        if (!mainList) return;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
        if (isFlagMenuOpen) disableBodyScroll(mainList as any);
        return () => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
            if (isFlagMenuOpen) enableBodyScroll(mainList as any);
        };
    }, [isFlagMenuOpen, appDisplayMode]);

    // Hide the backrdrop when the personnal menu is closed (triggered by user scroll in TopNagivation)
    useEffect(() => {
        if (!flagMenuState[0]) {
            menuActionsState[1].setFalse();
        }
    }, [flagMenuState[0]]);

    useUpdateEffect(() => {
        if (flagMenuState[0]) {
            flagMenuState[1].setFalse();
            menuActionsState[1].setFalse();
        }
    }, [location]);

    return {
        key: 'flagSlot',
        icon: (
            <Flex ref={flagMenuRef}>
                <Flag countryCode={countryCode} />
                <ChevronDown
                    styles={{
                        [`&.${svgIconClassName}`]: {
                            width: `${pxToRem(14)} !important`,
                            marginRight: pxToRem(-14),

                            [`& > svg`]: {
                                padding: pxToRem(3),
                                margin: pxArrayToRem([0, 6]),
                                ...(flagMenuState[0] && {
                                    transform: `rotate(-180deg)`,
                                }),
                                fill: 'inherit',

                                [`& path`]: {
                                    fill: 'inherit',
                                },
                            },
                        },
                    }}
                />
            </Flex>
        ),
        children: (Component, props) => {
            return (
                <Popup
                    open={flagMenuState[0]}
                    key={'flagSlotPopup'}
                    position="below"
                    align={'end'}
                    trigger={<Component {...props} />}
                    target={target}
                    onOpenChange={() => {
                        menuActionsState[1].toggle();
                        flagMenuState[1].toggle();
                    }}
                    trapFocus={true}
                    content={{
                        styles: ({ theme }) => ({
                            [`& .${popupContentSlotClassNames.content}`]: {
                                padding: 0,
                                [theme.screen.tablet]: {
                                    width: pxToRem(414),
                                    position: 'relative',
                                    top: pxToRem(12),
                                    right: pxToRem(-102),
                                },
                                [theme.screen.laptop]: {
                                    width: pxToRem(178),
                                    position: 'relative',
                                    top: pxToRem(28),
                                    right: 0,
                                },
                            },
                        }),
                        children: (
                            <Box styles={{ padding: pxArrayToRem([16, 10]) }}>
                                {countriesMenuItem.map((item, index) => {
                                    const {
                                        code,
                                        label,
                                        link: fallbackLink,
                                    } = item;

                                    const pathParts =
                                        location?.pathname
                                            .split('/')
                                            .filter(Boolean) ?? [];
                                    const shouldRedirectHome =
                                        pathsThatShouldRedirectToHome.includes(
                                            pathParts[0]
                                        );

                                    const pathAndSearch =
                                        (location?.pathname ?? '') +
                                        (location?.search ?? '');

                                    const link =
                                        swapTLDDomain(
                                            env,
                                            countryCode,
                                            code,
                                            fallbackLink
                                        ) +
                                        (shouldRedirectHome
                                            ? ''
                                            : pathAndSearch);

                                    const active = code === countryCode;
                                    return (
                                        <React.Fragment key={code}>
                                            <Grid
                                                key={code}
                                                columns={`1fr ${pxToRem(12.8)}`}
                                                onClick={() => {
                                                    setCountryCode(code);
                                                    flagMenuState[1].setFalse();
                                                    menuActionsState[1].setFalse();
                                                }}
                                                styles={{
                                                    padding: pxArrayToRem([
                                                        4, 0,
                                                    ]),
                                                    cursor: 'pointer',
                                                }}
                                                {...(!active && {
                                                    as: 'a',
                                                    href: link,
                                                })}
                                            >
                                                <Caption
                                                    variant="04"
                                                    weight={
                                                        active
                                                            ? 'medium'
                                                            : 'regular'
                                                    }
                                                    styles={{
                                                        lineHeight: 1.1,
                                                        color: theme
                                                            .siteVariables
                                                            .colors
                                                            .charcoal[250],
                                                    }}
                                                >
                                                    {label}
                                                </Caption>
                                                {active ? (
                                                    <CheckLine
                                                        size="1x"
                                                        variables={{
                                                            '1xSize':
                                                                pxToRem(12.8),
                                                        }}
                                                    />
                                                ) : undefined}
                                            </Grid>
                                            {index <
                                                countriesMenuItem.length -
                                                    1 && (
                                                <Divider
                                                    size={1}
                                                    variables={{
                                                        dividerPrimaryColor:
                                                            theme.siteVariables
                                                                .colors
                                                                .grey[125],
                                                    }}
                                                />
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </Box>
                        ),
                    }}
                />
            );
        },
    };
}

const Flag = ({ countryCode }: { countryCode: SupportedCountry }) => {
    const variables = { '1xSize': pxToRem(42) };
    switch (countryCode) {
        case 'NZ':
            return <FlagNZ size={'1x'} variables={variables} />;
        case 'AU':
            return <FlagAU size={'1x'} variables={variables} />;
        default:
            return null;
    }
};

interface CountryMenuItem {
    label: string;
    code: SupportedCountry;
    link: string;
    tld: string;
}
export const countriesMenuItem: CountryMenuItem[] = [
    {
        label: 'New Zealand',
        code: 'NZ',
        link: 'https://archipro.co.nz',
        tld: '.co.nz',
    },
    {
        label: 'Australia',
        code: 'AU',
        link: 'https://archipro.com.au',
        tld: '.com.au',
    },
];

// Need to pass in env
const swapTLDDomain = (
    env: TopnavClientEnvironment,
    country: SupportedCountry,
    replaceCountry: SupportedCountry,
    fallbackLink?: string
): string => {
    const cty = countriesMenuItem.find(c => c.code === country);
    const replaceWith = countriesMenuItem.find(c => c.code === replaceCountry);

    if (!cty || !replaceWith || env.version === 'local') {
        let host = env.host;
        if (!host || host === '') {
            host = fallbackLink as string;
        }

        return 'https://' + env.host;
    }
    // Replace first match which will be the tld
    return 'https://' + env.host.replace(cty.tld, replaceWith.tld);
};
